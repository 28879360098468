import ChartCore from '@/components/ChartCore';
import { chortColors } from '@/components/ChartCore/theme';
import { dict } from '@/hooks/useChangeLocale';
import type { HomeUserAnalysisResult } from '@/services/home/type';
import { Validator } from '@/utils/validator';
import { useCreation } from 'ahooks';

const Chart = ({ data }: { data?: HomeUserAnalysisResult[] }) => {
  const dataObj = useCreation(() => {
    return data
      ?.filter((v) => v.audienceCharacterType == 'active_hour' && Number(v.dimensions) >= 0)
      .sort((v1, v2) => Number(v1.dimensions) - Number(v2.dimensions))
      .reduce((obj, cur) => {
        const dimensions = dict('NUM_CLOCK', { num: cur.dimensions });
        obj[dimensions] = cur.metrics;
        return obj;
      }, {});
  }, [data]);

  const options = {
    tooltip: {
      trigger: 'axis',
      formatter: `{b} {c}${dict('PEOPLE')}`,
    },
    grid: {
      top: 30,
      left: 50,
      right: 50,
      bottom: 60,
    },
    legend: {},
    xAxis: {
      type: 'category',
      data: Object.keys(dataObj || {}),
      offset: 10,
    },
    yAxis: {
      type: 'value',
      name: dict('PEOPLE'),
    },
    series: [
      {
        data: Object.values(dataObj || {}).map((value, index) => ({
          value,
          itemStyle: {
            color: chortColors[index % chortColors.length],
          },
        })),
        type: 'bar',
      },
    ],
  };

  return <ChartCore option={options} notMerge empty={Validator.isNilEmpty(data)} style={{ height: 350 }} />;
};

export default Chart;
