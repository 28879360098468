import { useCreation, useMemoizedFn, useRequest } from 'ahooks';
import { Select, TreeSelect } from '@antd';
import type { SelectProps, TreeSelectProps } from '@antd';
import Http, { get } from '@/utils/request';
import React, { useState } from 'react';

type KisSelectProps = SelectProps;
interface Dealer {
  id: string;
  name: string;
}

interface CarType {
  id: number;
  name: string;
}

interface CarSery {
  id: number;
  name: string;
  carTypes: CarType[];
}

interface Brand {
  id: number;
  name: string;
  carSeries: CarSery[];
}

interface BrandAndDealerData {
  dealers: Dealer[];
  brands: Brand[];
}

export const useKiaBrandSelect = (): [React.FC<KisSelectProps>, React.FC<KisSelectProps>, React.FC<KisSelectProps>] => {
  const { data } = useRequest(() => get<BrandAndDealerData>('/shoppingGuide/dealer/brand/car'));
  const [seriesName, setSeriesName] = useState<string>();

  const [carSeriesOptions, dealerOptions] = useCreation(() => {
    const { brands, dealers } = data || { brands: [{ carSeries: [] }], dealers: [] };
    return [
      brands?.[0].carSeries.map((v) => ({ ...v, label: v.name, value: v.name })),
      dealers.map((v) => ({ ...v, label: v.name, value: v.id })),
    ];
  }, [data]);

  const carTypeOptions = useCreation(() => {
    if (!seriesName) return [];
    const serie = carSeriesOptions.find((v) => v.name == seriesName);
    return serie.carTypes.map((v) => ({ ...v, label: v.name, value: v.name }));
  }, [carSeriesOptions, seriesName]);

  const CarTypeSelect = useMemoizedFn((props: KisSelectProps) => {
    return <Select placeholder="请选择" {...props} options={carTypeOptions} />;
  });

  const CarSerySelect = useMemoizedFn((props: KisSelectProps) => {
    return (
      <Select
        placeholder="请选择"
        {...props}
        options={carSeriesOptions}
        onChange={(...rest) => {
          setSeriesName(rest[0] as string);
          props?.onChange?.(...rest);
        }}
      />
    );
  });

  const DealerSelect = useMemoizedFn((props: KisSelectProps) => {
    return <Select placeholder="请选择" {...props} options={dealerOptions} />;
  });

  return [CarSerySelect, CarTypeSelect, DealerSelect];
};

export const CityTreeSelect = (props: TreeSelectProps) => {
  const { data: cityOptions } = useRequest(
    async () => {
      const datas = await Http.get<any[]>('/shoppingGuide/cityTree');
      return datas.map((v) => ({
        title: v.name,
        value: v.name,
        isLeaf: false,
        selectable: false,
        children: v.children.map((v) => ({
          title: v.name,
          value: v.name,
        })),
      }));
    },
    { refreshDeps: [] },
  );

  return <TreeSelect placeholder="请选择" treeDefaultExpandAll={false} treeData={cityOptions} {...props} />;
};

export const ActiveStatusSelect: React.FC<KisSelectProps> = (props) => {
  return (
    <Select
      placeholder="请选择"
      {...props}
      options={['INIT', 'PROCESSING', 'SUCCESS', 'FAILURE'].map((v) => ({ label: v, value: v }))}
    />
  );
};
